export interface EntityInterface {
  id: number;
}
export interface NodeInterface extends EntityInterface {
  title: string;
  created?: number;
  changed?: number;
  path: string;
  routerLink?: string;
  fullUrl?: string;
  urlEncoded?: string;
}

export interface ArticleInterface extends NodeInterface {
  perex: string;
  body?: string;
  image?: ImageInterface;
  authorName?: string;
  publishedDateFormatted?: string;
  changedDateFormatted?: string;
  genre?: string;
  section?: string;
  tags?: Array<TagInterface>;
  isPr?: boolean;
}

export interface PageInterface extends NodeInterface {
  body?: string;
}

export interface TaxonomyTermInterface extends EntityInterface {
  title: string;
  path: string;
  description?: string;
  fullUrl?: string;
}

export interface SectionInterface extends TaxonomyTermInterface {
  routerLink?: string;
}

export interface TagInterface extends TaxonomyTermInterface {
  image?: ImageInterface;
  routerLink?: string;
}

export interface ImageInterface extends NodeInterface {
  copyright?: string;
  alt?: string;
  filemime?: string;
  width?: number;
  height?: number;
  url?: string;
  largeUrl?: string;
  squareUrl?: string;
  thumbnailUrl?: string;
  isGallery?: boolean;
  galleryImagesCount?: number;
  galleryRouterLink?: string;
  galleryRouterFragment?: string;
}

export interface GalleryInterface extends NodeInterface {
  images?: Array<ImageInterface>;
}

export interface MenuItemInterface extends EntityInterface {
  title: string;
  path: string;
  isExternal: boolean;
  isActive: boolean;
}

export class DetailEntity {
  getTitle(): string { return '' };
  getMetaDescription(): string { return '' };
  getMetaKeywords(): string { return '' };
  getFullUrl(): string { return '' };
}
export class Node extends DetailEntity {
  private entity: NodeInterface;
  constructor(entity: NodeInterface) {
    super();
    this.entity = entity;
  }

  override getTitle(): string { return this.entity.title };
  override getFullUrl(): string { return this.entity.fullUrl ? this.entity.fullUrl : '' };
}

export class Article extends DetailEntity {
  private entity: ArticleInterface;
  constructor(entity: ArticleInterface) {
    super();
    this.entity = entity;
  }

  override getTitle(): string { return this.entity.title };
  override getMetaDescription(): string { return this.entity.perex.replace(/<.*?>/g, '') };
  override getFullUrl(): string { return this.entity.fullUrl ? this.entity.fullUrl : '' };
  public getOgType(): string { return 'article' };
  public getImage() { return this.entity.image ? this.entity.image : null; }
}

export class Gallery extends DetailEntity {
  private entity: GalleryInterface;
  constructor(entity: GalleryInterface) {
    super();
    this.entity = entity;
  }

  override getTitle(): string { return this.entity.title };
  override getFullUrl(): string { return this.entity.fullUrl ? this.entity.fullUrl : '' };
}

export class Page extends DetailEntity {
  private entity: PageInterface;
  constructor(entity: PageInterface) {
    super();
    this.entity = entity;
  }
  override getTitle(): string { return this.entity.title };
  override getFullUrl(): string { return this.entity.fullUrl ? this.entity.fullUrl : '' };
}

export class TaxonomyTerm extends DetailEntity {
  private entity: TaxonomyTermInterface;

  constructor(entity: TaxonomyTermInterface) {
    super();
    this.entity = entity;
  }

  override getTitle(): string { return this.entity.title };

  override getMetaDescription(): string { return this.entity.description?.replace(/<.*?>/g, '') ?? '' };
  override getFullUrl(): string { return this.entity.fullUrl ? this.entity.fullUrl : '' };
}

export class Section extends DetailEntity {
  private entity: SectionInterface;
  constructor(entity: SectionInterface) {
    super();
    this.entity = entity;
  }

  override getTitle(): string { return this.entity.title };

  override getMetaDescription(): string { return this.entity.description?.replace(/<.*?>/g, '') ?? '' };
  override getFullUrl(): string { return this.entity.fullUrl ? this.entity.fullUrl : '' };
}

export class Tag extends DetailEntity {
  private entity: TagInterface;
  constructor(entity: TagInterface) {
    super();
    this.entity = entity;
  }

  override getTitle(): string { return this.entity.title };
  override getMetaDescription(): string { return this.entity.description?.replace(/<.*?>/g, '') ?? '' };
  override getFullUrl(): string { return this.entity.fullUrl ? this.entity.fullUrl : '' };
}

export class Image {
  private entity: ImageInterface;
  constructor(entity: ImageInterface) {
    this.entity = entity;
  }
}

export class MenuItem {
  private entity: MenuItemInterface;
  constructor(entity: MenuItemInterface) {
    this.entity = entity;
  }
}

