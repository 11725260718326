import { Injectable } from '@angular/core';
import {environment} from "../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {catchError, Observable, of} from "rxjs";
import {MultipleFieldTaxonomyTerm} from "./resultTypes";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class Drupal7Service {
  constructor(
    private http: HttpClient,
    private router: Router
  ) { }

  private sendRequest(path: string, args: Array<any>, queryArgs?: Object): Observable<any> {
    let requestUrl = environment.contentApiHost + '/' + path;
    if (args && args.length) {
      requestUrl += '/' + args.join('/');
    }
    if (queryArgs) {
      const ret: Array<any> = [];
      Object.entries(queryArgs).forEach(([key, value]) =>
        ret.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
      );
      requestUrl += '?' + ret.join('&');
    }

    return this.http.get(requestUrl).pipe(
      catchError(this.handleError(requestUrl, []))
    );
  }

  getNodeById(nid: number) {
    return this.sendRequest('node', [nid]);
  }

  getArticle(titlePath: string | null) {
    let nid = this.parseIdFromTitlePath(titlePath);
    // @ts-ignore
    return nid > 0 ? this.getNodeById(nid) : null;
  }

  getNode(titlePath: string | null) {
    let nid = this.parseIdFromTitlePath(titlePath);
    return this.sendRequest('node', [nid]);
  }

  getGalleryNode(nid: number) {
    return this.sendRequest('node', [nid]);
  }

  getTaxonomyTermById(tid: number | null) {
    return this.sendRequest('taxonomy_term', [tid]);
  }

  getTaxonomyTerm(titlePath: string | null) {
    let tid = this.parseIdFromTitlePath(titlePath);
    if (!tid) {
      this.getRedirect('tema/' + titlePath).subscribe(response => {
        if (response.status == 'success' && response.path) {
          let args = response.path.split('/');
          if (args[0] === 'taxonomy') {
            this.getTaxonomyTermById(args[2]).subscribe((data) => {
              if (data.vocabulary_machine_name == 'rubriky') {
                let section = {
                  routerLink: this.generateSectionPath(data.tid, data.name)
                }
                this.router.navigate([section.routerLink]);
              }
              else if (data.vocabulary_machine_name == 'temata') {
                let tag = {
                  routerLink: this.generateTagPath(data.tid, data.name)
                }
                this.router.navigate([tag.routerLink]);
              }
            });
          }
        }
      });
    }
    return tid ? this.getTaxonomyTermById(tid) : new Observable();
  }

  getViewArticles({viewName, displayId, args = [], offset = 0, limit = 10}: {viewName: string, displayId: string, args?: Array<any>, offset?: number, limit? : number}) {
    return this.sendRequest('views', [viewName], { display_id: displayId, args: args, offset: offset, limit: limit });
  }

  getViewTaxonomyTerms({viewName, displayId, args = [], offset = 0, limit = 10}: {viewName: string, displayId: string, args?: Array<any>, offset?: number, limit? : number}) {
    return this.sendRequest('views', [viewName], { display_id: displayId, args: args, offset: offset, limit: limit });
  }

  getSearchResults({searchText, offset = 0, limit = 10}: {searchText: string, offset?: number, limit? : number}) {
    return this.sendRequest('views', ['search_results'], { display_id: 'services_1', keys: searchText, offset: offset, limit: limit });
  }

  getMainMenu() {
    return this.sendRequest('menu', ['main-menu']);
  }

  subscribeToNewsletter(email: string): Observable<any> {
    return this.sendRequest('newsletter/subscribe', [], {e: email});
  }

  confirmNewsletter(hash: string): Observable<any> {
    return this.sendRequest('newsletter/confirm', [], {hash: hash});
  }

  getRedirect(path: string) {
    return this.sendRequest('redirect/get', [], {path: path});
  }

  increaseNodeStatistics(nid: number) {
    return this.sendRequest('statistics/inc', [], {nid: nid});
  }

  formatTimestamp(timestamp: number): string {
    const dateObj = new Date(timestamp * 1000);
    return dateObj.getDate() + '.&nbsp;' + (dateObj.getMonth() + 1) + '.&nbsp;' + dateObj.getFullYear();
  }

  getTidValue(value: MultipleFieldTaxonomyTerm): number | undefined {
    return typeof value.und !== 'undefined' ? value.und[0].tid : undefined;
  }

  getGenreName(genreTid: number): string {
    let genres: {[id: number]: string} = {
      27167: 'analýza',
      27168: 'anketa',
      27169: 'glosa',
      27170: 'komentář',
      27171: 'komerční sdělení',
      27172: 'názor',
      27173: 'nekrolog',
      27174: 'osobnost',
      27175: 'povídka',
      27176: 'recenze',
      27177: 'reportáž',
      27178: 'rozhovor',
      27179: 'satira',
      27180: 'zajímavost',
      27181: 'zpráva',
    }
    return genres[genreTid];
  }

  getSectionName(sectionTid: number): string {
    let sections: {[id: number]: string} = {
      0: 'Ostatní',
      21653: 'Názory a komentáře',
      769: 'Publicistika',
      768: 'Zdraví a styl',
      18220: 'Historie',
      2025: 'Osobnosti',
      18252: 'Zajímavosti',
      21098: 'Společnost',
      15804: 'Kam za čím',
    }
    return sections[sectionTid];
  }

  getFileUrlFromUri(uri: string): string {
    return uri.replace('public://', environment.mediaBaseUrl + '/sites/default/files/');
  }

  getImageStyleUrlFromUri(uri: string, style: string): string {
    return uri.replace('public://', environment.mediaBaseUrl + '/sites/default/files/styles/' + style + '/public/');
  }

  generateNodePath(nid: number, title: string) {
    const path = this.transliterate(title, "-") + "-" + nid;
    return path.toLowerCase();
  }

  generateArticlePath(nid: number, title: string, sectionTid: number) {
    const path = this.transliterate(this.getSectionName(sectionTid), "-") + '/' + this.transliterate(title, "-") + "-" + nid;
    return path.toLowerCase();
  }

  generateSectionPath(tid: number, title: string) {
    const path = '/sekce/' + this.transliterate(title, "-") + "-" + tid;
    return path.toLowerCase();
  }

  generateTagPath(tid: number, title: string) {
    const path = '/tema/' + this.transliterate(title, "-") + "-" + tid;
    return path.toLowerCase();
  }

  private parseIdFromTitlePath(titlePath: string | null): number | null {
    return titlePath ? this.parseNumberFromTitlePath(titlePath) : null;
  }

  private parseNumberFromTitlePath(titlePath: string): number {
    let pathArgs = titlePath.split('-');
    let lastArg = pathArgs.slice(-1).pop();
    // @ts-ignore
    return parseInt(lastArg);
  }

  private transliterate(word: string | undefined, replaceSpacesWith: string | null = null): string {
    if (typeof word === "undefined") {
      return '';
    }
    let answer: string = "";
    let a: {[id: string]: string} = {
      "Ё":"YO",
      "Й":"I",
      "Ц":"TS",
      "У":"U",
      "К":"K",
      "Е":"E",
      "Н":"N",
      "Г":"G",
      "Ш":"SH",
      "Щ":"SCH",
      "З":"Z",
      "Х":"H",
      "Ъ":"'",
      "ё":"yo",
      "й":"i",
      "ц":"ts",
      "у":"u",
      "к":"k",
      "е":"e",
      "н":"n",
      "г":"g",
      "ш":"sh",
      "щ":"sch",
      "з":"z",
      "х":"h",
      "ъ":"'",
      "Ф":"F",
      "Ы":"I",
      "В":"V",
      "А":"A",
      "П":"P",
      "Р":"R",
      "О":"O",
      "Л":"L",
      "Д":"D",
      "Ж":"ZH",
      "Э":"E",
      "ф":"f",
      "ы":"i",
      "в":"v",
      "а":"a",
      "п":"p",
      "р":"r",
      "о":"o",
      "л":"l",
      "д":"d",
      "ж":"zh",
      "э":"e",
      "Я":"Ya",
      "Ч":"CH",
      "С":"S",
      "М":"M",
      "И":"I",
      "Т":"T",
      "Ь":"'",
      "Б":"B",
      "Ю":"YU",
      "я":"ya",
      "ч":"ch",
      "с":"s",
      "м":"m",
      "и":"i",
      "т":"t",
      "ь":"'",
      "б":"b",
      "ю":"yu",
      "á":"a",
      "é":"e",
      "í":"i",
      "ý":"y",
      "ó":"o",
      "ú":"u",
      "ů":"u",
      "ž":"z",
      "š":"s",
      "č":"c",
      "ř":"r",
      "ď":"d",
      "ť":"t",
      "ň":"n",
      "ě":"e",
      ",":"",
      ".":"",
      ";":"",
      ":":"",
      "?":"",
      "!":"",
      "(":"",
      ")":"",
      "@":"",
      "#":"",
      "$":"",
      "%":"",
      "^":"",
      "&":"",
      "*":"",
      "+":"",
      "/":"",
      "\\":"",
      ">":"",
      "<":"",
      "=":"",
    };

    if (replaceSpacesWith) {
      a[" "] = replaceSpacesWith;
    }

    return word.toLowerCase().split('').map(function (char) {
      return typeof a[char] !== 'undefined' ? a[char] : char;
    }).join("");
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   *
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
