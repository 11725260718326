<header>
  <nav tabindex="-1" class="max-w-[1060px] mx-auto">
    <div class="p-4 border-b border-black">
      <div class="flex items-center w-full sm:hidden">
        <div class="w-1/6 text-left">
          <!-- Mobile menu button-->
          <button (click)="mobileMenuHidden = !mobileMenuHidden" type="button" class="relative left-0 inset-y-0 rounded-full p-2 transition duration-150 ease-in-out text-black hover:bg-orange-800 hover:text-white focus:outline-none focus:ring-1 focus:ring-inset focus:ring-black" aria-controls="mobile-menu" aria-expanded="false">
            <span class="absolute -inset-0.5"></span>
            <span class="sr-only">Open main menu</span>
            <!--
              Icon when menu is closed.

              Menu open: "hidden", Menu closed: "block"
            -->
            <svg class="block h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
            <!--
              Icon when menu is open.

              Menu open: "block", Menu closed: "hidden"
            -->
            <svg class="hidden h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>

        <div class="text-3xl text-center font-serif text-black mb-0 font-normal w-2/3">
          <a routerLink="" class="no-underline">čtidoma.cz</a>
        </div>

        <div class="w-1/6 text-right">
          <app-mobile-search-btn></app-mobile-search-btn>
        </div>
      </div>
      <div class="flex items-center justify-center sm:items-stretch">
        <div class="hidden sm:block">
          <!-- Desktop menu, show/hide based on menu state. -->
          <app-main-menu class="flex sm:space-x-1 xl:space-x-2 flex-wrap justify-center"></app-main-menu>
        </div>
      </div>

      <!-- Mobile menu, show/hide based on menu state. -->
      <div id="mobile-menu" [class.hidden]="mobileMenuHidden">
        <app-main-menu [isDesktop]="false" class="space-y-1 px-2 pb-3 pt-2"></app-main-menu>
      </div>

      <app-mobile-search-input></app-mobile-search-input>
    </div>
  </nav>
</header>


<div class="hidden sm:block max-w-[1060px] mx-auto">
  <div class="flex p-6 border-b border-black items-stretch">
    <div class="w-1/4 flex items-center text-xs">
      <app-day-info></app-day-info>
    </div>
    <div class="w-1/2 items-center">
      <div class="text-3xl md:text-4xl lg:text-5xl text-center font-serif text-black mb-0 font-normal">
        <a routerLink="" class="no-underline">čtidoma.cz</a>
      </div>
    </div>
    <div class="w-1/4 flex items-stretch">
      <app-searchform class="flex w-full"></app-searchform>
    </div>
  </div>
</div>

<section class="content">
  <!-- <app-messages></app-messages> -->
  <router-outlet></router-outlet>
</section>

<div class="bg-white">
  <div class="max-w-[1060px] mx-auto p-6">
    <app-ad-position
      [positionName]="AdPositionName.footerboard"
    ></app-ad-position>
  </div>
  <div class="max-w-[1060px] mx-auto p-6 lg:hidden">
    <app-newsletter-subscription></app-newsletter-subscription>
  </div>
</div>


<footer id="footer" class="bg-orange-100 dark:bg-orange-800">
  <div class="max-w-[1060px] mx-auto p-6 bg-orange-100 dark:bg-orange-800">

    <div class="md:flex md:justify-between md:items-center">
      <div class="mb-6 md:mb-0">
        <a href="https://www.ctidoma.cz/" class="flex items-center text-5xl no-underline">
          čtidoma.cz
        </a>
      </div>
      <div class="p-6 bg-white rounded-xl shadow-lg relative text-center font-bold">
        Máte zajímavou informaci? Chcete spolupracovat?<br />
        Kontaktujte šéfredaktora Martina Chalupu: <a href="mailto:chalupa@ctidoma.cz" class="text-orange-800">chalupa@ctidoma.cz</a>
      </div>
    </div>
    <hr class="my-6 border-black sm:mx-auto dark:border-gray-700 lg:my-8" />
    <div class="text-sm font-bold mb-2">© Centa, a.s.</div>
    <div class="text-xs">
      Jakékoli použití obsahu včetně převzetí, šíření či dalšího užití a zpřístupňování textových či obrazových materiálů bez písemného souhlasu společnosti Centa,a.s. je zakázáno.
      Čtenář svým přihlášením do jakékoli soutěže na našem webu dává souhlas s tím, že v případě, že se stane výhercem této soutěže, může být jeho jméno na webu publikováno.
      Centa, a.s. využívala licenci ČTK a využívá fotografie z <a href="https://cz.depositphotos.com/">Depositphotos</a>.
    </div>
  </div>

</footer>
