import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FrontpageComponent } from "./frontpage/frontpage.component";
import { ArticleDetailComponent } from "./article/article-detail/article-detail.component";
import {PageDetailComponent} from "./page-detail/page-detail.component";
import {SectionDetailComponent} from "./section-detail/section-detail.component";
import {TagDetailComponent} from "./tag-detail/tag-detail.component";
import {SearchResultsComponent} from "./search/search-results/search-results.component";
import {ArticleDiscussionComponent} from "./article/article-discussion/article-discussion.component";
import {NewsletterConfirmationComponent} from "./newsletter-confirmation/newsletter-confirmation.component";
import {PageNotFoundComponent} from "./pagenotfound/pagenotfound.component";
import {RedirectNegotiatorComponent} from "./redirect-negotiator/redirect-negotiator.component";

const routes: Routes = [
  {
    path: '',
    component: FrontpageComponent,
    title: '[Ctidoma.cz] - [Politika, zajímavosti, osobnosti, zdraví, životní styl, názory, kultura]'
  },
  {
    path: 'clanek/:category/:titlepath',
    component: ArticleDetailComponent,
    title: 'Článek | ČtiDoma.cz',
  },
  {
    path: 'clanek/:category/:titlepath/diskuse',
    component: ArticleDiscussionComponent,
    title: 'Diskuse | ČtiDoma.cz',
  },
  {
    path: 'sekce/:titlepath',
    component: SectionDetailComponent,
    title: 'Kategorie | ČtiDoma.cz',
  },
  {
    path: 'tema/:titlepath',
    component: TagDetailComponent,
    title: 'Téma | ČtiDoma.cz',
  },
  {
    path: 'stranka/:titlepath',
    component: PageDetailComponent,
    title: 'Stránka | ČtiDoma.cz',
  },
  {
    path: 'search/:keys',
    component: SearchResultsComponent,
    title: 'Výsledky vyhledávání | ČtiDoma.cz',
  },
  {
    path: 'newsletter/confirm/add/:hash',
    component: NewsletterConfirmationComponent,
    title: 'Potvrdit odběr newsletteru | ČtiDoma.cz',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking',
})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
