import {Component, Inject} from '@angular/core';
import {AdDisplayType} from "../ad-provider/ad-provider";

@Component({
  selector: 'app-ad-seznam-popup',
  standalone: true,
  templateUrl: './ad-seznam-popup.component.html',
  styleUrls: ['./ad-seznam-popup.component.css']
})
export class AdSeznamPopupComponent {
  displayType: AdDisplayType;
  sssp: any | undefined;
  breakPoint: number | undefined;
  zoneId: string | undefined;
  popupAdEl: any | undefined;
  popupAdCloseEl: any | undefined;
  adDiv: HTMLDivElement | undefined;

  constructor() {
    this.sssp = (window as any).sssp;
    this.displayType = window.innerWidth > 480 ? AdDisplayType.desktop : AdDisplayType.mobile;
  }

  //Set cookie with 30 min expiration
  private getCookie(cname: string) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  //Set cookie with 30 min expiration
  private setCookie(value: any) {
    var d = new Date();
    d.setTime(d.getTime() + (30 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();
    document.cookie = "seznam-pop-up-ad-first-look=" + value + ";" + expires + ";path=/";
  }

  //Add necessarily styles to head of page
  private appendStyles() {
    var css =
      "#seznam-pop-up-ad{ position: fixed; height: auto; width: 100%; background: rgba(0,0,0,.7);bottom: 0; left: 0; right: 0;  text-align: center; padding: 2px 0; z-index: 1001; transition: height 0.5s; }" +
      "#seznam-pop-up-ad-close{background: rgba(0,0,0,.7); margin-top: -31px; position: absolute; top: 0; right: 0; color: #fff; cursor: pointer; text-align: center; padding: 8px; height: 31px; font-family: Arial, Helvetica, sans-serif; font-size: 14px; line-height: 1; width: 140px; }" +
      ".seznam-pop-up-ad-hide{ height: 0 !important; padding: 0!important; margin: 0!important;}";
    var style = document.createElement('style');
    style.innerHTML = css;
    document.head.appendChild(style);
  }

  //Create Ad divs
  private createAdDivs() {
    this.adDiv = document.createElement('div');
    this.adDiv.setAttribute('id', 'seznam-pop-up-ad');
    this.adDiv.innerHTML = ('<div id="seznam-pop-up-ad-close">Zavřít reklamu</div>' + '<div id="ssp-zone-' + this.zoneId + '" style="margin: 0 auto;"></div>');
    document.body.appendChild(this.adDiv);

    let _popup = this;
    this.popupAdEl = document.getElementById("seznam-pop-up-ad");
    this.popupAdCloseEl = document.getElementById("seznam-pop-up-ad-close");
    this.popupAdCloseEl.addEventListener("click", function () {
      _popup.hideAd();
    });
  }

  //Hide Ad
  private hideAd() {
    this.popupAdEl.classList.add("seznam-pop-up-ad-hide");
    this.popupAdCloseEl.style.display = "none";
  }

  public getPopUp(breakPoint: number, zoneId: string) {
    this.breakPoint = breakPoint;
    this.zoneId = zoneId;
    if (window.innerWidth <= this.breakPoint) {
      //if (window.innerWidth <= breakPoint && sssp.displaySeznamAds() && document.getElementById(onScrollElementId)) {
      if (!this.getCookie("seznam-pop-up-ad-first-look")) {
        this.setCookie(false);
      }
      this.appendStyles();

      let createdAd = document.getElementById("seznam-pop-up-ad");
      if (!createdAd && this.getCookie("seznam-pop-up-ad-first-look") === "false") {
        this.setCookie(true);
        this.createAdDivs();
        this.sssp.getAds([{
          "zoneId": this.zoneId,
          "id": "ssp-zone-" + this.zoneId,
          "width": 500,
          "height": 200
        }]);
      }
    }
  }
}
